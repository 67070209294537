export default class InputGetSchedule
{
  constructor(photo, name, title, info,  desc, time) 
  {
    this.photo = photo;
    this.name = name;
    this.title = title;
    this.info = info;
    this.desc = desc;
    this.time = time;
  }    
}