<template>
  <div>
    <div class="actions row col-lg-12 mb-5 p-0 m-0 justify-content-end">
      <div class="actions-right row col-lg-6 row justify-content-end p-0 m-0">
        <button type="button" class="btn btn-primary" @click="addSchedule">{{ (' Add Schedule') }}</button>
      </div>
    </div>
    <CTableWrapper ref="table" hover striped :caption="caption" :items="items" :actions="actions" :fields="fields" clickable-rows @row-clicked="rowClicked" @action-clicked="actionClicked" @update-requested="updateItemsRequested" :loading="loading">
    </CTableWrapper>
  </div>
</template>
 ««
<script>
import { mapState, mapActions } from 'vuex'
import CTableWrapper from '../controls/PaginatedTable.vue'
import router from '@/router';
import InputGetSchedule from "@/classes/InputGetSchedule";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  components: { CTableWrapper },
  data () {
    return {
      caption: ('Schedule'),
      fields: [
        { key: 'photo', label: ('Photo'), _classes: 'font-weight-bold', sortable: false }, 
        { key: 'name', label: ('Name'), _classes: 'font-weight-bold', sortable: false },
        { key: 'title', label: ('Title'), _classes: 'font-weight-bold', sortable: false },
        { key: 'info', label: ('Info'), _classes: 'font-weight-bold', sortable: false },
        { key: 'desc', label: ('Desc'), _classes: 'font-weight-bold', sortable: false},
        { key: 'time', label: ('Time'), _classes: 'font-weight-bold', sortable: false}
      ],
      actions: [
        { key: 'view', label: ('view') },
        { key: 'remove', label: ('remove') }
      ],
      loading: false
    }
  },
  computed: {
    ...mapState('schedule', ['listOutput']),
    ...mapState('schedule', ['removeTask']),
    scheduleInfo: function() {
      return this.listOutput ? this.listOutput : null;
    },
    items: function()
    {
      let result = [];

      if(this.scheduleInfo)
      {
          result = this.scheduleInfo ? this.scheduleInfo : [];

          result.forEach(items => {
              items["time"] = UTILS_HELPER.formatDate(items.time);

          });
      }

      return result;
    },
  },
  mounted: function()
  {
    this.updateItemsRequested();
  },
  methods: {
    ...mapActions('schedule', ['getList']),
    ...mapActions('schedule', ['removeSchedule']),
    rowClicked (item, index) {
      this.$router.push({name: "EditSchedule", params: { schedule: item } });
    },
    actionClicked(item, action){
      switch(action.key)
      {
        case 'view':
        {
          this.$router.push({name: "EditSchedule", params: { schedule: item } });
          break;
        }
        case 'remove':
          {
            if(confirm("Wanna remove this schedule?")){
              this.removeSchedule(item.id);
          }
          break;
        }
        default:
        {
          throw "The provided action is not supported!"; 
        }
      }
    },
    updateItemsRequested()
    {
      //Make request
      this.loading = true;

      let input = new InputGetSchedule();
      this.getList(input); 
    },
    addSchedule () 
    {
      router.push({ path: '/schedule/add' });
    },
  },
  watch:
  {
    removeTask: function(val)
    {
      // Schedule was deleted, reload list
      if(val)
      {
        this.updateItemsRequested();
      }
    },
    listOutput: function(val)
    {
      if(val){
        this.loading = false;
      }
    }
  }
}
</script>

<style>
th
{
  border-top: none !important;
}

tr
{
  cursor: pointer;
}

.status-button
{
  border: none;
  padding: 0;
  text-decoration: underline;
  color: #321fdb;
}
</style> 